import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import BackgroundImage from "gatsby-background-image"

const Proj = ({children,desc,img,to}) => (
    

    <>

 <BackgroundImage tag="div" className="item d-flex"  fluid={img}>
   <AniLink className="ptext d-flex flex-fill flex-column align-self-end align-items-start" fade style={{backgroundImage: `linear-gradient(0deg, rgba(48, 48, 48, 0.5), rgba(0, 0, 0, 0))`}} to={to}>
    <div>
      <h3>{children}</h3>
      <p>{desc}</p>
       </div>
   </AniLink>
  </BackgroundImage>
  </>
 )
export default Proj