import React from "react"
import { graphql}  from 'gatsby'
import {Container, Row,Col} from "react-bootstrap"
import {Link,TransitionLink} from 'gatsby-plugin-transition-link'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import ReactMarkdown from "react-markdown"
import Layout from "../components/layout"
import Proj from "../components/project"
import SEO from "../components/seo"
import BackgroundImage from 'gatsby-background-image'
import Fade from 'react-reveal/Fade';

const CategoryTemp = ({data}) => { 
  const backgroundFluidImageStack = [
    data.strapiAndcat.heroimg.childImageSharp.fluid,
    `linear-gradient(0deg, rgba(48, 48, 48, 0.4), rgba(0, 0, 0, 0.2))`
  ].reverse()
  
  return(
  <Layout>
    <SEO title={data.strapiAndcat.cname} />
<Container fluid>
  <BackgroundImage tag="div" className="row cat align-items-center" fluid={backgroundFluidImageStack} >
  

    <Col md={{span:8, offset:2}}  className="cat-title">
      <h1 className="mb-3">{data.strapiAndcat.heroname}</h1>
      <h3 className="mb-2"><em>{data.strapiAndcat.heroname2}</em></h3>
      <ReactMarkdown className="mt-4" source={data.strapiAndcat.paragraph}/>
    </Col>
 
  </BackgroundImage>
</Container>
<Container fluid >
<Fade bottom>

<div className="grid">
{data.strapiAndcat.andprojects.map(andproject => (
  <Proj desc={andproject.shortdesc} to={`/${andproject.permalink}`} img={andproject.pheroimg.childImageSharp.fluid}>{andproject.pname}</Proj>

))}
{/*  <Proj desc="design package for the Blood & Collar Film Festival" to="/project" img="images/jurni/jurni2.jpg">Jurni</Proj>
 <Proj desc="even The Beautiful Game has an ugly side" to="/project" img="images/panal.jpeg">One game changed anything</Proj>
 <Proj desc="even The Beautiful Game has an ugly side" to="/project" img="images/panal.jpeg">One game changed anything</Proj>
 <Proj desc="even The Beautiful Game has an ugly side" to="/project" img="images/panal.jpeg">One game changed anything</Proj> */}
</div>

</Fade>
</Container>
   
  </Layout>
);}

export default CategoryTemp

export const query = graphql`
query AndcatTemplate($id: String!) {
        strapiAndcat(id: {eq: $id}) {
            cname
            heroname
            heroname2
            paragraph
            heroimg{
              childImageSharp {
                fluid(quality: 90, maxWidth: 2560) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            andprojects{
              pname
              permalink
              shortdesc
              pheroimg{
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1200) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
    }
}
`
